var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('h4',[_vm._v("Trash bin request")]),_c('br'),_c('form',{ref:"formContainer",staticClass:"form-horizontal vld-parent",on:{"submit":function($event){$event.preventDefault();return _vm.requestTrashBin.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Unit*")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.trash.unit_id.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.mappedUserUnits},model:{value:(_vm.trash.unit_id),callback:function ($$v) {_vm.$set(_vm.trash, "unit_id", $$v)},expression:"trash.unit_id"}}),(_vm.submitted && _vm.$v.trash.unit_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.trash.unit_id.required)?_c('span',[_vm._v("Unit is required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Number of trash bins*")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.trash.no_of_trash_bins.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.drawings},model:{value:(_vm.trash.no_of_trash_bins),callback:function ($$v) {_vm.$set(_vm.trash, "no_of_trash_bins", $$v)},expression:"trash.no_of_trash_bins"}}),(_vm.submitted && _vm.$v.trash.no_of_trash_bins.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.trash.no_of_trash_bins.required)?_c('span',[_vm._v("Please choose a value")]):_vm._e()]):_vm._e()],1)])]),_c('submitButton')],1),_c('paymentModal',{ref:"paymentWindow",attrs:{"page":"Trash Bin","requestId":_vm.requestId,"referenceNumber":_vm.refernceNumber,"modalTitle":_vm.modalTitle,"adminFees":_vm.adminFees,"urgentFees":_vm.urgentFees,"securityDepFees":_vm.securityDepFees,"adminVatFees":_vm.adminVatFees,"serviceFees":_vm.serviceFees,"updatedAdminFees":_vm.updatedAdminFees,"updatedAdminVatFees":_vm.updatedAdminVatFees}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }